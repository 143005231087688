import axios from "axios";

export function list(param) {
  return axios.get("/wd/list", { params: param });
}

// export function markSuccess(id){
//     return axios.post("/wd/markSuccess/" + id)
// }

export function markFail(id, failReason){
    let param = {
        id:id,
        failReason:failReason
    }
    return axios.post("/wd/markFail", param);
}

export function trans(id, transferMethod){
    let param = {
        id:id,
        transferMethod:transferMethod
    }
    return axios.post("/wd/trans", param);
}


export function queryConfirm(id){
    return axios.post("/wd/queryConfirmTrans/" + id)
}