<template>
    <div id="user-list">

        <el-form :inline="true" :model="form">
            <el-form-item>
                <el-input v-model="form.id" placeholder="ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form.userId" placeholder="用户ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form.email" placeholder="用户邮箱"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="form.paymentMethod" placeholder="提款方式" clearable>
                    <el-option v-for="item in Object.keys(WITHDRAWSL_METHOD)" :key="item"
                        :label="WITHDRAWSL_METHOD[item]" :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="form.status" placeholder="处理状态" clearable>
                    <el-option v-for="item in Object.keys(WITHDRAWSL_ORDER_STATUS)" :key="item"
                        :label="WITHDRAWSL_ORDER_STATUS[item]" :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker v-model="queryTimeRange" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="loadList">查询</el-button>
            </el-form-item>
        </el-form>

        <el-table :data="page.list" border>
            <el-table-column label="ID">
                <template #default="{ row }">
                    {{ row.id }}
                    <el-tag :type="choseTag(row.status)">{{ WITHDRAWSL_ORDER_STATUS[row.status] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发起时间">
            </el-table-column>
            <el-table-column label="用户">
                <template #default="{ row }">
                    [{{ row.userId }}]{{ row.email }}
                </template>
            </el-table-column>
            <el-table-column label="金额" prop="amount">

            </el-table-column>
            <el-table-column label="提款方式">
                <template #default="{ row }">
                    {{ WITHDRAWSL_METHOD[row.withdrawalMethod] }}
                </template>
            </el-table-column>
            <el-table-column label="收款账户信息">
                <template #default="{ row }">
                    <AccountInfo :withdrawalMethod="row.withdrawalMethod" :accountInfo="row.accountInfo" />
                </template>
            </el-table-column>
            <el-table-column label="转账方式">
                <template #default="{ row }">

                    <el-popover placement="bottom" trigger="hover" width="800">
                        <el-descriptions :column="1" border>
                            <el-descriptions-item label="转账订单号" :labelStyle="{ width: '100px' }">
                                {{ row.transferOrderNo }}
                            </el-descriptions-item>
                        </el-descriptions>
                        <span slot="reference">{{ TRANSFER_METHOD[row.transferMethod] }}</span>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template #default="{ row }">
                    <template v-if="row.status == 'WAIT'">
                        <el-popover trigger="click" title="发送转账">
                            <el-form :inline="true">
                                <el-form-item>
                                    <el-select v-model="row.transferMethod" placeholder="提款方式" clearable>
                                        <el-option v-for="item in Object.keys(TRANSFER_METHOD)" :key="item"
                                            :label="TRANSFER_METHOD[item]" :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button size="mini" type="primary"
                                        @click="doTrans(row.id, row.transferMethod)">确认</el-button>
                                </el-form-item>
                            </el-form>
                            <el-button slot="reference" type="success" size="medium">转账</el-button>
                        </el-popover>
                    </template>

                    <template v-if="row.status == 'PROCESSING'">
                        <el-button  type="primary" size="medium" @click="doQueryConfirm(row.id)">查询确认</el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-popover trigger="click" title="是否确认失败退款？">
                            <el-form :inline="true">
                                <el-form-item>
                                    <el-input v-model="row.failReason" placeholder="请输入理由"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button size="mini" type="primary"
                                        @click="doMarkFail(row.id, row.failReason)">确认</el-button>
                                </el-form-item>
                            </el-form>
                            <el-button slot="reference" type="danger" size="medium">失败退款</el-button>
                        </el-popover>
                    </template>
                    <el-popover v-if="row.status == 'FAIL'" placement="left" trigger="click" :content="row.failReason">
                        <el-button type="danger" slot="reference">失败原因</el-button>
                    </el-popover>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="text-align: center" :page-count="page.totalPage" :current-page="form.pageNo"
            layout="prev, pager, next" @current-change="changePage">
        </el-pagination>
    </div>
</template>
<script>
import { list, markFail,trans, queryConfirm, } from './wdo'
import { WITHDRAWSL_METHOD, WITHDRAWSL_ORDER_STATUS, TRANSFER_METHOD } from '../../util/constants'
import AccountInfo from '../../components/AccountInfo.vue';
import msg from "../../util/msg.js";
export default {
    name: "WithdrawalOrderList",

    components: {
        AccountInfo,
    },

    data() {
        return {

            TRANSFER_METHOD,
            WITHDRAWSL_METHOD,
            WITHDRAWSL_ORDER_STATUS,

            queryTimeRange: null,
            form: {
                pageNo: 1,
                pageSize: 20,
                id: null,
                userId: null,
                email: null,
                withdrawalMethod: null,
                status: null,
                ctStart: null,
                ctEnd: null,
            },

            page: {
                list: null,
                totalPage: null,
            },
        };
    },

    methods: {
        loadList() {
            if (this.queryTimeRange != null) {
                this.form.ctStart = this.queryTimeRange[0];
                this.form.ctEnd = this.queryTimeRange[1];
            } else {
                this.form.ctStart = null;
                this.form.ctEnd = null;
            }
            list(this.form).then((data) => {
                this.page = data;
            });
        },

        doQueryConfirm(id) {
            queryConfirm(id).then(data => {
                msg.success();
                this.loadList();
            })
        },

        doMarkFail(id, failReason) {
            if (failReason == null || failReason === '') {
                msg.fail('请输入理由')
                return;
            }
            markFail(id, failReason).then(data => {
                msg.success();
                this.loadList();
            })
        },

        doTrans(id, transferMethod){
            trans(id, transferMethod).then(data => {
                msg.success();
                this.loadList();
            });
        },

        choseTag(status) {
            if (status == 'SUCCESS') {
                return 'success'
            }
            if (status == 'FAIL') {
                return 'danger'
            }

            return '';
        },



    },

    created() {
        this.loadList();
    },

};
</script>