<template>
    <div id="accountInfo">
        <el-descriptions v-if="withdrawalMethod == 'BANK'" class="margin-top" :column="1" :size="size" border>
            <el-descriptions-item label="银行">
                {{ accountInfo.bank }}
            </el-descriptions-item>
            <el-descriptions-item label="卡号">
                {{ accountInfo.cardNo }}
            </el-descriptions-item>
            <el-descriptions-item label="持卡人">
                {{ accountInfo.name }}
            </el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>

export default {
    name: "AccountInfo",
    props: {
        accountInfo: {
            type: Object,
            default: null,
        },
        withdrawalMethod: {
            type: String,
            default: null,
        }
    },

    data() {
        return {
           
        };
    },

    created() {
        
    },

    computed() {

    },
};
</script>